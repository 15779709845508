.testimonial-container-bg {
  /* background-color: #4cc5d933; */
  background-color: #d4e7f8;
}
.testimonial_img {
  height: 330px !important;
}
.testimonial_bg {
  background-color: #89d8ea;
}
.testimonial-video {
  transition: transform 1s !important;
  border-radius: 7px;
  cursor: pointer;
}
.testimonial-video:hover {
  transform: scale(1.05) !important;
}
.testimonial-video img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  height: 230px;
}
@media screen and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
  .fSLKPH {
    min-height: auto !important;
    padding: 5px !important;
  }
  .pricing_col .App {
    min-height: auto !important;
    padding: 5px !important;
  }

  .pricing_col {
    margin: auto !important;
  }
}
.fSLKPH {
  min-height: auto !important;
}
@media screen and (min-width: 426px) and (max-width: 767px) {
  .pricing_div_right {
    margin-left: 40% !important;
  }
}
.testimonial-cus {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 370px;
}
/* width */
.testimonial-cus::-webkit-scrollbar {
  width: 9px;
}
/* Track */
.testimonial-cus::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}
/* Handle */
.testimonial-cus::-webkit-scrollbar-thumb {
  background: #0dbaff;
  border-radius: 10px;
}
/* Handle on hover */
.testimonial-cus::-webkit-scrollbar-thumb:hover {
  background: #0dbaff;
}
.testimonial-cus-photo {
  margin: 0 auto;
}
.testimonial-card-text {
  font-size: smaller !important;
}
.card-body {
  padding: 10px !important;
}
.testimonial-animation {
  animation: fadeLeftSide 2s ease-in-out;
}
.testimonial-animation-facebook {
  animation: fadeRightSide 2s ease-in-out;
}

@keyframes fadeLeftSide {
  from {
    opacity: 0;
    transform: translateX(-300px);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeRightSide {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
  }
}
