.row{
  width: 100%;
}
.pricing_bg {
  background: #f6f2ed !important;
}
.pricing_header {
  margin: 5px auto;
}
.pricing_icon {
  width: 32px;
  color: #0dbaff;
  margin: 10% -40%;
}
.pricing_paragraph {
  display: flex;
  align-items: center;
  font-size: large;
}
.pricing_col {
  margin: 7% auto !important;
}
@media screen and (max-width: 767px) {
  .pricing_col {
    margin: 0 !important;
  }
}
.pricing_center_col {
  margin-bottom: 0%;
}
.pricing_div {
  margin-left: 40%;
}
.pricing_div_right {
  margin-left: 20%;
}
.pricingbtn {
  color: #000000;
  border: 2px solid #0dbaff !important;
  box-shadow: none;
  border-radius: 0.7rem;
  box-shadow: 0px 4px 12px #0003;
  margin-left: 1rem !important;
  display: block;
  padding: 0.5rem 1rem;
}
.pricingbtn:hover {
  background: #0dbaff;
  color: #ffffff;
  border-radius: 0.7rem;
  margin-left: 1rem;
}
.pricing_btn_active {
  background: #0dbaff;
  color: #ffffff !important;
  transition: transform 0.5s;
  transform: scale(1.12);
}

@media screen and (max-width: 425px) {
  .pricing_div_right {
    margin-left: 28% !important;
  }
  .pricing_div {
    margin-left: 28%;
  }
}
