.changePage_container {
    position: relative;
	width: 600px;
	height: 600px;
	border-radius: 50%;
    margin: 0 auto;
}
.changePage_center_part {
    width: 170px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
}
.changePage_center_part::before {
    content: 'Why Us?';
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 24px;
    color: rgb(25, 99, 146);
    text-align: center;
}
.changePage_polygon {
    position: absolute;
	width: 85%;
	top: 38%;
	left: 7%;
    padding: 15px;
}
.changePage_polygon img {
    width: 70px;
    height: 80px;
    margin: 17px;
}
.changePage_polygon::after {
    content: '';
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateY(-120%);
	width: 2px;
	height: 40px;
	background-color: #0DBAFF;
}
.changePage_polygon_bg {
    display: flex;
    width: 100px;
    height: 120px;
    transition: transform .5s ease-out;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    position: relative;
    /* box-shadow: 0 0 15px rgba(0,0,0,0.8) inset !important; */
    animation: fadeInRights 2s ease-in-out;
    background: linear-gradient(#9bdaf6 0%, #0DBAFF 100%);
    /* background: linear-gradient(#4cc5d945 0%, #4cc5d930 100%); */
    /* background-color: #80808014; */
}
.changePage_polygon span {
    position: absolute;
    left: -5%;
    color: rgb(25, 99, 146)!important;
}


@keyframes fadeInRights {
  from {
    opacity: 0;
    transform: translateX(-250px);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInText {
    from {
      opacity: 0;
      transform: translateX(-250px);
    }
    to {
      opacity: 1;
    }
  }

.rotate1 {
    transform: rotate(47deg);
}
.rotate1 div {
    transform: rotate(-47deg);
}
.rotate1_text {
    transform: rotate(-47deg) translateY(-57px) translateX(-88px);
    animation: fadeInText 2s ease-in-out;
}
.rotate2 {
    transform: rotate(90deg);
}
.rotate2 div {
    transform: rotate(-90deg);
}
.rotate2_text {
    transform: rotate(-90deg) translateY(-77px) translateX(74px);
    animation: fadeInText 2s linear;
}
.rotate3 {
    transform: rotate(135deg);
}
.rotate3 div {
    transform: rotate(-135deg);
}
.rotate3_text {
    transform: rotate(-135deg) translateY(34px) translateX(243px);
    animation: fadeInText 2s ease-in-out;
}
.rotate4 {
    transform: rotate(180deg);
}
.rotate4 div {
    transform: rotate(-180deg);
}
.rotate4_text {
    transform: rotate(-180deg) translateY(75px) translateX(144px);
    animation: fadeInText 2s ease-in-out;
}
.rotate5 {
    transform: rotate(220deg);
}
.rotate5 div {
    transform: rotate(-220deg);
}
.rotate5_text {
    transform: rotate(-220deg) translateY(53px) translateX(125px);
    animation: fadeInText 2s ease-in-out;
}
.rotate6 {
    transform: rotate(270deg);
}
.rotate6 div {
    transform: rotate(-270deg);
}
.rotate6_text {
    transform: rotate(-270deg) translateY(109px) translateX(-73px);
    animation: fadeInText 2s ease-in-out;
}
.rotate7 {
    transform: rotate(315deg);
}
.rotate7 div {
    transform: rotate(-315deg);
}
.rotate7_text {
    transform: rotate(-315deg) translateY(-64px) translateX(-198px);
    animation: fadeInText 2s ease-in-out;
}
.rotate8_text {
    transform: rotate(-360deg) translateY(-72px) translateX(-249px);
    animation: fadeInText 2s ease-in-out;
}

@media screen and (max-width:768px) {
    .rotate1_text {
        transform: rotate(-47deg) translateY(-38px) translateX(-27px);
        width: 120px;
    }
    .rotate3_text {
        transform: rotate(-135deg) translateY(78px) translateX(168px);
        width: 120px;
    }
    .rotate4_text {
        transform: rotate(-180deg) translateY(26px) translateX(72px);
        width: 120px;
    }
    .rotate8_text {
        transform: rotate(-360deg) translateY(-91px) translateX(-65px);
        width: 120px;
    }
}

@media screen and (max-width:466px) {
    .changePage_container {
        width: 300px;
        height: 300px;
    }
    .changePage_center_part {
        width: 60px;
        margin: 19px auto;
    }
    .changePage_center_part::before {
        font-size: 10px;
        text-align: center;
    
    }
    .changePage_polygon {
        width: -25px;
        top: 40%;
        left: 7%;
    }
    .changePage_polygon_bg {
        width: 50px;
        height: 70px;
    }
    .changePage_polygon::after {
        height: 15px;
        transform: translateY(0%);
    }
    .changePage_polygon span {
        font-size: 10px;
        word-wrap: break-word;
    }
    .changePage_polygon img {
        width: 50px;
        height: 40px;
        margin: 11px 0;
    }
    .rotate1_text {
        transform: rotate(-47deg) translateY(-40px) translateX(-8px);
    width: 65px;
    }
    .rotate2_text {
        transform: rotate(-90deg) translateY(-47px) translateX(43px);
    }
    .rotate3_text {
        transform: rotate(-135deg) translateY(31px) translateX(114px);
        width: 98px;
    }
    .rotate4_text {
        transform: rotate(-180deg) translateY(39px) translateX(58px);
        width: 79px;
    }
    .rotate5 {
        transform: rotate(220deg);
        margin: 9px -10px;
    }
    .rotate5 div {
        transform: rotate(-220deg);
    }
    .rotate5_text {
        transform: rotate(-220deg) translateY(43px) translateX(45px);
        width: 100px;
    }
    .rotate6_text {
        transform: rotate(-270deg) translateY(67px) translateX(-31px);
    }
    .rotate7_text {
        transform:rotate(-315deg) translateY(-51px) translateX(-72px);
        width: 65px;
    }
    .rotate8_text {
        transform: rotate(-360deg) translateY(-72px) translateX(-31px);
        width: 65px;
    }
}

@media screen and (min-width:467px) and (max-width: 899px) {
    .changePage_container {
        width: 400px;
        height: 400px;
    }
    .changePage_center_part {
        width: 60px;
        margin: 19px auto;
    }
    .changePage_center_part::before {
        font-size: 10px;
        text-align: center;
    
    }
    .changePage_polygon {
        width: -25px;
        top: 40%;
        left: 7%;
    }
    .changePage_polygon_bg {
        width: 60px;
        height: 80px;
    }
    .changePage_polygon::after {
        height: 40px;
        transform: translateY(-40%);
    }
    .changePage_polygon span {
        font-size: 10px;
        word-wrap: break-word;
    }
    .changePage_polygon img {
        width: 60px;
        height: 50px;
        margin: 11px 0;
    }
    .rotate1_text {
        transform: rotate(-47deg) translateY(-28px) translateX(-2px);
        width: 98px;
    }
    .rotate2_text {
        transform: rotate(-90deg) translateY(-45px) translateX(43px);
    }
    .rotate3_text {
        transform: rotate(-135deg) translateY(47px) translateX(123px);
        width: 104px;
    }
    .rotate4_text {
        transform: rotate(-180deg) translateY(26px) translateX(39px);
        width: 65px;
    }
    .rotate5 {
        transform: rotate(220deg);
        margin: 9px -10px;
    }
    .rotate5 div {
        transform: rotate(-220deg);
    }
    .rotate5_text {
        transform: rotate(-220deg) translateY(87px) translateX(-13px);
    }
    .rotate6_text {
        transform: rotate(-270deg) translateY(13px) translateX(-137px);
        width: 100px;
    }
    .rotate7_text {
        transform: rotate(-315deg) translateY(-71px) translateX(-87px);
        width: 65px;
    }
    .rotate8_text {
        transform: rotate(-360deg) translateY(-91px) translateX(-25px);
        width: 65px;
    }
}

@media screen and (max-width:320px) {
    .changePage_container {
        left: 2%;
    }
}